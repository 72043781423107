// Import necessary libraries
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Helmet } from "react-helmet";
// import "./Banner.css"; 

export default function Banner() {
  const bannerData = [
    {
      imgSrc: "glory stone images/Glowry Stone Slider/Glowry Stone Slider1.jpg",
      location: "Glowry Stone Resort",
      title: "Your Dream Wedding, Our Dream Location",
      price: "Contact : +91 7066665554 /+91 9145557771",
      days: "One Day Picnic Spot",
    },
    {
      imgSrc: "glory stone images/Glowry Stone Slider/Glowry Stone Slider2.jpg",
      location: "Glowry Stone Resort",
      title: "A Day With Full of Fun, Laughter, and Cake",
      price: "Contact : +91 7066665554 /+91 9145557771",
      days: "Resort Near Pune",
    },
    {
      imgSrc: "glory stone images/Glowry Stone Slider/Glowry Stone Slider3.jpg",
      location: "Glowry Stone Resort",
      title: "Your Adventure,Your Resort",
      price: "Contact : +91 7066665554 /+91 9145557771",
      days: "Camping Places Near Pune",
    },
    {
      imgSrc: "glory stone images/Glowry Stone Slider/Glowry Stone Slider4.jpg",
      location: "Glowry Stone Resort",
      title: "Where Family Bonding Meets Relaxation",
      price: "Contact : +91 7066665554 /+91 9145557771",
      days: "One Day Picnic Spot Near Pune for Family",
    },
    {
      imgSrc: "glory stone images/Glowry Stone Slider/Glowry Stone Resort Slider8.jpg",
      location: "Glowry Stone Resort",
      title: "Peaceful retreat offering luxurious accommodations and scenic views",
      price: "Contact : +91 7066665554 /+91 9145557771",
      days: "Forest Resorts Near Pune",
     
    },
    {
      imgSrc: "glory stone images/Glowry Stone Slider/Glowry Stone Slider5.jpg",
      location: "Glowry Stone Resort",
      title: "Exquisite Rooms, Exceptional Service",
      price: "Contact : +91 7066665554 /+91 9145557771",
      days: "Forest Resorts Near Ahmednagar",

    },
    {
      imgSrc: "glory stone images/Glowry Stone Slider/Glowry Stone Slider6.jpg",
      location: "Glowry Stone Resort",
      title: "Limitless Plates, Infinite Flavor",
      price: "Contact : +91 7066665554 /+91 9145557771",
      days: "Forest Resorts Near Ahmednagar",
    },
    {
      imgSrc: "glory stone images/Glowry Stone Slider/Glowry Stone Slider7.jpg",
      location: "Glowry Stone Resort",
      title: "Your Perfect Day, Just a Picnic Away",
      price: "Contact : +91 7066665554 /+91 9145557771",
      days: "Mango Mahotsav in Ahmednagar",
    },
    {
      imgSrc: "glory stone images/Glowry Stone Slider/Glowry Stone Slider8.jpg",
      location: "Glowry Stone Resort",
      title: "The Perfect Escape for Your Team",
      price: "Contact : +91 7066665554 /+91 9145557771",
      days: "Resort Near Mumbai",
    },
  ];

  // Slider settings for mobile responsiveness
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Default for large screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024, // Tablet devices (landscape)
        settings: {
          slidesToShow: 3, // Show 3 slides on tablets
        },
      },
      {
        breakpoint: 768, // Mobile devices (landscape)
        settings: {
          slidesToShow: 2, // Show 2 slides on small screens
        },
      },
      {
        breakpoint: 480, // Mobile devices (portrait)
        settings: {
          slidesToShow: 1, // Show 1 slide on very small screens
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>
          Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort -
          About| One Day Picnic Spot Near Ahmednagar | Corporate Outing Resorts
          Near Pune | Camping Places Near Pune
        </title>
        <meta
          name="description"
          content="Glowry Stone Resort, located near Ahmednagar, Maharashtra, offers an ideal destination for a one-day picnic, family outings, and corporate retreats. Book your stay for a perfect getaway from Pune."
        />
        <meta
          name="keywords"
          content="One Day Picnic Spot Near Ahmednagar, One Day Trip Near Ahmednagar, Picnic Spot Near Ahmednagar, Picnic Destination Near Pune, Corporate Outing Resorts Near Ahmednagar, Glowry Stone Resort Near Pune, Family Picnic, Weekend Getaway Near Pune, Resorts Near Pune"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Contact: +91 7066665554 / +91 9145557771 | Home | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar | Farm House On Rent in Pune"
        />
        <meta
          property="og:description"
          content="Glowry Stone Resort offers a perfect family-friendly picnic destination near Ahmednagar, ideal for one-day trips and corporate outings. Located just a short drive from Pune."
        />
        <meta
          property="og:image"
          content="https://www.glowrystone.com/ogimage.jpg"
        />
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar"
        />
        <meta
          name="twitter:description"
          content="Glowry Stone Resort, a serene retreat near Ahmednagar, offers family picnics, corporate outings, and more. Book your escape from Pune now!"
        />
        <meta name="twitter:image" content="https://www.glowrystone.com/ogimage.jpg" />
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
      </Helmet>
      <div className="banner trending overflow-hidden">
        <div className="banner-main">
          <Slider {...settings} className="banner-slider">
            {bannerData.map((item, index) => (
              <div key={index} className="col-lg-4 p-0">
                <div className="trend-item1 box-shadow bg-white text-center">
                  <div className="trend-image position-relative">
                    <img
                      src={item.imgSrc}
                      alt={item.location}
                      className="img-fluid"
                    />
                    <div className="trend-content1 px-4 top-50 bottom-inherit translatey-50 mytextbox">
                      <h5 className="theme1 mb-0">
                        <i className="flaticon-location-pin"></i> {item.location}
                      </h5>
                      <h2 className="mb-1 white slider-text">
                        <a href="grid-leftfilter.html" className="white">
                          {item.title}
                        </a>
                      </h2>
                      <div className="rating-main pb-1">
                        <div className="rating">
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="fa fa-star checked"></span>
                          <span className="ms-2 white">{item.reviews}</span>
                        </div>
                      </div>
                      <div className="entry-meta text-center">
                        <div className="entry-author mb-1">
                          <p className="mb-0 white">
                            <span className="theme1 fw-bold fs-5"> {item.price}</span>
                          </p>
                        </div>
                        <div className="entry-author">
                          <i className="icon-calendar white"></i>
                          <span className="fw-bold white"> {item.days}</span>
                        </div>
                      </div>
                    </div>
                    <div className="overlay"></div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
}
