import React from 'react';
import './PackageSection.css'; // Optional: Create a separate CSS file for styling

const PackageSection = () => {
  return (
    <section className="package-section">
      <h2 className="section-title">Exclusive Packages at Glowry Stone Resort</h2>
      <p className="section-description">
        Escape to luxury and tranquility at Glowry Stone Resort! We offer a range of thoughtfully designed packages 
        to cater to your needs, whether you’re looking for a relaxing day, a special getaway, or a corporate retreat. 
        Explore our exclusive offers below.
      </p>

      <div className="package-cards-container">
        
        {/* Package 1: One Day Stay */}
        <div className="package-card">
          <h3 className="package-title">1. One Day Stay Package</h3>
          <p className="package-price">₹2500</p>
          <p className="package-details">
            Includes:
            <ul>
              <li>AC Luxury Accommodation</li>
              <li>Morning Breakfast,  Lunch,  Dinner, &  High Tea</li>
              <li>Customized Unlimited Food</li>
              <li>Check-in: 12:00 PM | Check-out: 12:00 PM</li>
              <li>Perfect for a relaxing day with all-inclusive meals and comfort</li>
            </ul>
          </p>
        </div>

        {/* Package 2: One Day Return */}
        <div className="package-card">
          <h3 className="package-title">2. One Day Return Package</h3>
          <p className="package-price">₹900 per person</p>
          <p className="package-details">
            Includes:
            <ul>
              <li>Morning Breakfast</li> <br/>
              <li>Lunch</li><br/>
              <li>High Tea</li><br/>
              <li>Check-in: 5:00 AM | Check-out: 5:00 PM</li>
              <li>Perfect for a day trip with delicious meals and scenic views</li>
            </ul>
          </p>
        </div>

        {/* Package 3: Only Cottage Stay */}
        <div className="package-card">
          <h3 className="package-title">3. Only Cottage Stay</h3>
          <p className="package-price">₹2000 per cottage</p>
          <p className="package-details">
            Includes:
            <ul>
              <li>Stay from 9:00 AM to 6:00 PM</li>
              <li>Private and comfortable cottage for a day of relaxation</li>
              <li>Check-in: 9:00 AM | Check-out: 6:00 PM</li>
              <li>Perfect for those who need a peaceful retreat</li>
            </ul>
          </p>
        </div>

        {/* Package 4: Corporate Package & Customized Package */}
        <div className="package-card">
          <h3 className="package-title">4. Corporate Package & Customized Packages</h3>
          <p className="package-price">On Call</p>
          <p className="package-details">
            Tailored for businesses, organizations, and corporate teams. 
            We offer customized packages with flexible options to suit your needs.
            <strong>Contact us for more details and bookings!</strong>
          </p>
        </div>

        {/* Package 5: Destination Wedding Package */}
        <div className="package-card wedding-package">
          <h3 className="package-title">5. Destination Wedding Package</h3>
          <p className="package-price">On Call</p>
          <p className="package-details">
            <strong>Celebrate your love at Glowry Stone Resort in a magical destination wedding!</strong> 
            Our exclusive wedding package includes everything you need for an unforgettable celebration:
            <ul>
              <li>- Customized Wedding Ceremony with Scenic Views</li>
              <li>- Luxury Accommodation for the Bride, Groom, and Guests</li>
              <li>- Gourmet Catering & Personalized Menu Options</li>
              <li>- Elegant Decorations and Floral Arrangements</li>
              <li>- Special Pre-wedding and Post-wedding Events</li>
              <li>- Experienced Wedding Planner to Take Care of All Details</li>
            </ul>
          </p>
        </div>

      </div>

      <div className="contact-info">
        <h4 className='text-orange'>Contact Us for More Information or Custom Requests</h4>
        <p>For personalized experiences and package bookings, please <strong>call us at </strong> 
          <a href="tel:+917066665554" className='text-black fw-bold'>+91 7066665554</a> or 
          <a href="tel:+919145557771" className='text-black fw-bold'>+91 9145557771</a> or 
          <a href="mailto:reservation@glowrystone.com">email us at reservation@glowrystone.com</a>.
        </p>
      </div>
    </section>
  );
}

export default PackageSection;
