import Slider from "react-slick";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import './Page.css';
import GallerySlider from "./GallerySlider";
import Contactbox from "./Contactbox";
import RecentPost from "./RecentPost";
import Contact from "./Contact";
import ContactSection from "./ContactSection";
import { Helmet } from 'react-helmet';


export default function GlowryStoneResortNearPune(){

    const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [

        {
          "question": "What is the distance of Glowry Stone Resort from Pune?",
          "answer": "Glowry Stone Resort is located approximately 70 kilometers from Pune, making it an ideal weekend getaway for those looking to escape the hustle and bustle of city life. The drive takes about 1.5 to 2 hours, depending on traffic conditions."
        },
        {
          "question": "What type of accommodations does Glowry Stone Resort offer?",
          "answer": "Glowry Stone Resort offers a range of accommodations, including luxurious cottages, family suites, and deluxe rooms, all designed to provide ultimate comfort and privacy. Each room is equipped with modern amenities, offering a perfect blend of luxury and nature."
        },
        {
          "question": "Is Glowry Stone Resort pet-friendly?",
          "answer": "Yes, Glowry Stone Resort is a pet-friendly resort. You can bring your furry companions along and enjoy the open spaces, nature trails, and comfortable accommodations with your pets."
        },
        {
          "question": "What activities are available at Glowry Stone Resort?",
          "answer": "Glowry Stone Resort offers a variety of activities for guests of all ages, including: Trekking, Outdoor games and sports, Zip-lining, Nature walks, Swimming pool, Indoor games, Team-building activities for corporate groups, Relaxing spa treatments."
        },
        {
          "question": "Does Glowry Stone Resort have facilities for corporate outings?",
          "answer": "Yes, Glowry Stone Resort is an ideal destination for corporate outings. The resort offers spacious conference rooms, outdoor team-building spaces, and customized packages for corporate groups looking to combine work with relaxation."
        },
        {
          "question": "What are the dining options at Glowry Stone Resort?",
          "answer": "Glowry Stone Resort features an on-site multi-cuisine restaurant that serves delicious dishes prepared with fresh, local ingredients. Guests can enjoy a range of cuisines including Indian, Continental, and Chinese. The resort also offers outdoor dining options for a more relaxed experience amidst nature."
        },
        {
          "question": "Is Glowry Stone Resort suitable for family getaways?",
          "answer": "Yes, Glowry Stone Resort is the perfect destination for family getaways. With spacious accommodations, child-friendly activities, a swimming pool, and plenty of open spaces for family bonding, it’s an excellent choice for families looking to enjoy quality time together in nature."
        },
        {
          "question": "Can I book Glowry Stone Resort for a romantic getaway?",
          "answer": "Absolutely! Glowry Stone Resort is ideal for a romantic getaway. The resort offers serene and private settings with romantic dining experiences, nature walks, cozy rooms, and scenic views that will make your stay special. Whether for a honeymoon or a weekend retreat, the resort provides a perfect romantic ambiance."
        },
        {
          "question": "Does Glowry Stone Resort offer adventure activities?",
          "answer": "Yes, Glowry Stone Resort offers a variety of adventure activities including trekking, zip-lining, rock climbing, and camping. It’s an ideal destination for adventure enthusiasts looking to experience nature and adrenaline in one place."
        },
        {
          "question": "How can I book my stay at Glowry Stone Resort?",
          "answer": "Booking a stay at Glowry Stone Resort is easy! You can visit our website and fill out the booking form, or you can call our reservation team directly to inquire about availability and special offers. We also provide options for advance booking and customized packages for group bookings, corporate retreats, and family vacations."
        }
      
      
  ];
  
      const testimonials = [
        
            {
              "quote": "Glowry Stone Resort was the perfect destination for our family getaway! Located just a short drive from Pune, it was the ideal spot for a quick weekend break. The resort is surrounded by lush greenery, providing a peaceful and refreshing environment. Our kids had a blast at the play area and loved the adventure activities like trekking and zip-lining. We were also impressed by the spacious family rooms and the attentive staff who made sure all our needs were met. Whether you're looking to relax or engage in outdoor activities, Glowry Stone Resort offers the best of both worlds. Highly recommend it for families!",
              "author": "- Priya & Family (Pune)"
            },
            {
              "quote": "My husband and I were looking for a quiet, romantic retreat near Pune, and Glowry Stone Resort exceeded our expectations! The resort’s tranquil atmosphere, coupled with stunning views of the surrounding mountains, made it the perfect escape. We enjoyed private dinners under the stars and a relaxing spa session that left us feeling rejuvenated. The staff was incredibly friendly and ensured our stay was smooth and memorable. It’s the ideal place for couples who want to unwind and enjoy quality time in a beautiful setting. We can’t wait to return!",
              "author": "- Richa & Ankit (Mumbai)"
            }
    
          
        ];
  
        
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
    };
  
    const hotelImages = [
      {
        place: "Hotel-Images/Hotel In Viman Nagar.jpg",
        text: "Hotel In Viman Nagar",
        path:'hotel-in-viman-nagar'
      },
      {
        place: "Hotel-Images/Hotel In viman nagar for stay.jpg",
        text: "Hotels in Viman Nagar for Stay",
        path:'viman-nagar-hotels-for-stay'
      },
      {
        place: "Hotel-Images/Lodge in Viman nagar pune.jpg",
        text: "Lodge in Viman Nagar Pune",
        path:'lodge-in-viman-nagar-pune'
      },
      {
        place: "Hotel-Images/Hotels Near Viman Nagar Airport Pune.jpg",
        text: "Hotels Near Viman Nagar Airport Pune",
        path:'hotels-near-viman-nagar-airport-pune'
      },
      {
        place: "Hotel-Images/Pune Airport Hotel.jpg",
        text: "Pune Airport Hotel",
        path:'pune-airport-hotel'
      },
      {
        place: "Hotel-Images/Hotel In Wadgoan Sheri.jpg",
        text: "Hotel in Wadgaon Sheri",
        path:'hotel-wadgaon-sheri'
      },
      {
        place: "Hotel-Images/Online hotel booking in pune.jpg",
        text: "Online Hotel Booking in Pune",
        path:'online-hotel-booking-pune'
      },
      {
        place: "Hotel-Images/Hotel for stay in lohegoan Airport.jpg",
        text: "Hotel For Stay in Lohegaon Airport",
        path:'hotel-for-stay-lohegaon-airport'
      }
  
    
    ];

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "Place",
      "name": "Accommodation Near Supa MIDC",
      "logo":"https://www.glowrystone.com/logo",
      "image": "https://www.glowrystone.com/glory stone images/Glowry Stone Resort keyword/Glowry Stone Resort near pune.jpg", // Replace with your actual image URL
      "description": "Discover the best accommodation options near Supa MIDC, Ahmednagar. Whether you're looking for luxury hotels, cheap resorts, or corporate stays, we have the best options for families, couples, and corporate groups.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Supa MIDC Area",
        "addressLocality": "Ahmednagar",
        "addressRegion": "Maharashtra",
        "postalCode": "414111",
        "addressCountry": "IN"
      },
      "url": "https://www.glowrystone.com/Glowry-Stone-Resort-Near-Pune",
      "sameAs": [
        "https://www.facebook.com/example", // Replace with actual social media links
        "https://www.instagram.com/example"
      ],
      "offers": {
        "@type": "Offer",
        "url": "https://www.glowrystone.com/Glowry-Stone-Resort-Near-Pune",
        "priceCurrency": "INR",
        "price": "Starting from INR 2000 per night",
        "eligibleRegion": "IN"
      },
      "performer": {
        "@type": "Organization",
        "name": "Example Accommodation Near Supa MIDC",
        "sameAs": "https://www.glowrystone.com"
      }
    };

    
  
    return(
        <>

<Helmet>
      {/* Title Tag */}
      <title>Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort | Best Accommodation Near Supa MIDC | Resorts, Hotels & Corporate Stays</title>

      {/* Meta Tags */}
      <meta
        name="description"
        content="Looking for accommodation near Supa MIDC, Ahmednagar? Explore the best luxury hotels, resorts, and corporate accommodation options for families, couples, and corporate groups."
      />
      <meta
        name="keywords"
        content="luxury hotels in Supa MIDC, resorts in Supa MIDC, accommodation near Supa MIDC, corporate accommodation Supa MIDC, hotels near Supa MIDC, resorts in Ahmednagar, best resorts in Ahmednagar, resorts for family near Ahmednagar, resorts for couples near Ahmednagar"
      />
      <meta name="author" content="Example Accommodation Near Supa MIDC" />
      <meta name="robots" content="index, follow" />

      {/* Open Graph for Social Sharing */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Best Accommodation Near Supa MIDC | Resorts, Hotels & Corporate Stays" />
      <meta
        property="og:description"
        content="Discover the best hotels and resorts near Supa MIDC, Ahmednagar. Choose from luxury resorts, cheap stays, and corporate accommodation for all types of travelers."
      />
      <meta property="og:url" content="https://www.glowrystone.com/Glowry-Stone-Resort-Near-Pune" />
      <meta property="og:image" content="https://www.glowrystone.com/glory stone images/Glowry Stone Resort keyword/Glowry Stone Resort near pune.jpg" />
      <meta property="og:site_name" content="Example Accommodation Near Supa MIDC" />

      {/* Canonical Link */}
      <link rel="canonical" href="https://www.example.com/accommodation-near-supa-midc" />

      {/* Structured Data (JSON-LD) */}
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
    <section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h2 className="mb-3 text-white">Glowry Stone Resort Near Pune</h2>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                   <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>


    {/* ========================= */}

    <section className="trending recent-articles pb-6 keywordsection">
      <div className="container">
      <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="glory stone images/Glowry Stone Resort keyword/Glowry Stone Resort near pune.jpg" className="img-fluid" alt="Glowry Stone Resort near pune" />
              </div>
              <h2>Glowry Stone Resort Near Pune: A Perfect Getaway for Relaxation and Adventure</h2>

<p>If you are looking for a serene escape from the hustle and bustle of city life, Glowry Stone Resort near Pune is the perfect destination for you. Nestled in the lap of nature, just a short drive away from Pune, this charming resort offers an ideal blend of relaxation, adventure, and team-building opportunities, making it a top choice for family getaways, corporate outings, and weekend retreats.</p>

<p>Whether you're planning a day trip, a weekend retreat, or a corporate team-building outing, Glowry Stone Resort near Pune provides the perfect setting for all kinds of escapes. Let’s explore why this resort is becoming one of the best places near Pune for a peaceful yet fun-filled vacation.</p>

<h2>Best Resort Near Pune: Experience Unmatched Comfort</h2>

<p>Glowry Stone Resort stands out as one of the best resorts near Pune due to its exceptional services, serene location, and wide range of activities for guests of all ages. Whether you’re planning a quick getaway or a week-long retreat, the resort’s proximity to Pune (just about a 1.5-hour drive away) makes it the ideal choice for a peaceful yet exciting vacation.</p>

<h2>Luxury Resort Near Me: Experience Royal Hospitality</h2>

<p>At Glowry Stone Resort, we believe that luxury should never be compromised. Our luxury resort near Pune offers spacious rooms, private cottages, and suites that are elegantly designed with modern amenities and comfort. With world-class services like in-room dining, a gourmet restaurant, and private pools, our guests enjoy a premium experience in the lap of nature.</p>

<h2>Best Resorts in Ahmednagar: Your Tranquil Escape</h2>

<p>Located near Ahmednagar, Glowry Stone Resort offers the perfect blend of nature and luxury, making it one of the best resorts in Ahmednagar. Whether you’re looking to relax in luxury or enjoy adventurous activities, our resort offers something for everyone. The stunning landscapes, peaceful ambiance, and unparalleled services set us apart as a top-rated resort in the region.</p>

<h2>Family-Friendly Resorts: Perfect for Family Getaways</h2>

<p>Glowry Stone Resort is a family-friendly resort designed to cater to the needs of families seeking quality time away from the city. With spacious rooms, engaging activities for children, and plenty of outdoor space for family bonding, this resort is a fantastic option for family vacations. Kids can enjoy the swimming pool, indoor games, nature walks, and various recreational activities while parents unwind and relax.</p>

<h2>Weekend Getaway Resort: The Ultimate Short Escape</h2>

<p>Looking for a weekend getaway resort near Pune? Glowry Stone Resort is the perfect choice for a short and rejuvenating escape. Whether you're planning a weekend trip with your loved one, friends, or family, our resort offers a serene environment and various amenities to ensure a relaxing weekend. With the resort's proximity to Pune and its peaceful surroundings, it's an ideal place to relax and recharge.</p>

<h2>Eco-Friendly Resorts: Sustainability Meets Comfort</h2>

<p>At Glowry Stone Resort, we care about the environment. We are proud to be an eco-friendly resort, incorporating sustainable practices into our operations. From solar-powered energy systems to organic food options and waste reduction programs, we ensure that your stay contributes to environmental conservation. If you are eco-conscious and want to stay at a resort that blends luxury with sustainability, Glowry Stone Resort is the place for you.</p>


<h2>Resorts with Scenic Views: A Picture-Perfect Setting</h2>

<p>Surrounded by lush greenery, rolling hills, and picturesque landscapes, Glowry Stone Resort offers some of the most scenic views near Pune. Whether you're relaxing by the pool, enjoying a nature walk, or dining at our restaurant, you'll be treated to breathtaking views of the mountains and valleys that surround the resort. It's a perfect setting for those seeking tranquility and natural beauty.</p>

<h2>Pet-Friendly Resorts: Bring Your Furry Friends Along</h2>

<p>For pet lovers, we offer pet-friendly accommodations where your furry friends are welcome to join you on your vacation. Our pet-friendly resort allows pets to enjoy the open spaces, gardens, and nature trails with their owners. We understand that pets are part of the family, and at Glowry Stone Resort, they are treated with the same love and care.</p>

<h2>Mountain View Resorts: Enjoy Views of the Majestic Hills</h2>

<p>At Glowry Stone Resort, guests can enjoy stunning mountain views that create the perfect backdrop for relaxation and rejuvenation. Our property is ideally located with unobstructed views of the majestic hills surrounding the resort, offering a peaceful environment that’s perfect for unwinding.</p>

<h2>Resorts with Pool and Spa: Indulge in Pampering</h2>

<p>Glowry Stone Resort offers a luxurious pool and spa experience to pamper yourself after a long day of adventure or relaxation. Dive into our well-maintained outdoor pool or treat yourself to a soothing spa session that includes massages, facials, and other wellness treatments. Our spa services are designed to rejuvenate both the body and mind, making it the perfect addition to your stay.</p>

<h2>Romantic Resorts for Couples: A Perfect Getaway for Two</h2>

<p>For couples looking for a romantic escape, Glowry Stone Resort offers the perfect setting. With serene mountain views, cozy accommodations, and intimate dining experiences, it's an ideal destination for a romantic resort near Pune. Whether you're celebrating an anniversary, honeymoon, or just looking for a quiet retreat, our resort offers an intimate and tranquil atmosphere that’s perfect for couples.</p>

<h2>Budget Resorts: Affordable Luxury</h2>

<p>At Glowry Stone Resort, we believe that luxury doesn't have to come at a steep price. Offering a variety of budget-friendly options without compromising on comfort and amenities, our resort is a budget resort near Pune that ensures everyone can enjoy a luxurious experience within their budget. We offer different packages that include accommodation, meals, and activities at affordable rates, making it easy to plan your dream getaway without breaking the bank.</p>

<h2>Resorts for Corporate Retreats: Perfect for Team Building and Relaxation</h2>

<p>Looking for the perfect resort for a corporate retreat near Pune? Glowry Stone Resort offers the ideal setting for corporate groups to unwind, relax, and bond. With spacious conference rooms, outdoor spaces for team-building activities, and various adventure options for corporate outings, our resort provides everything you need to make your corporate retreat both productive and enjoyable.</p>

<h2>Adventure Resorts: For the Thrill-Seeker in You</h2>

<p>If you're looking for an adventure resort near Pune, Glowry Stone Resort is the place to be. We offer a wide range of outdoor activities such as trekking, zip-lining, rock climbing, and more. Whether you're planning a fun-filled weekend with friends or a team-building outing, our resort is packed with adventure-filled activities that will get your adrenaline pumping.</p>

<h2>Nature Resorts: Immerse Yourself in Natural Beauty</h2>

<p>Glowry Stone Resort is the perfect nature resort near Pune. Surrounded by lush greenery and stunning landscapes, our resort offers guests the opportunity to connect with nature in a serene and peaceful setting. Enjoy nature walks, bird-watching, and the tranquility of our green spaces, making it an ideal place for nature lovers to unwind.</p>

<h2>Weekend Resorts for Couples: Rekindle Romance Amidst Nature</h2>

<p>Looking for a weekend resort for couples near Pune? Glowry Stone Resort provides the perfect romantic setting for couples. Enjoy a relaxing weekend in the lap of nature, with cozy rooms, scenic views, candle-lit dinners, and a variety of rejuvenating activities to make your time together unforgettable.</p>


<h2>Glowry Stone Resort in Pune Contact Number:- </h2>

<p>Contact Glowry Stone Resort at <strong>[+91 7066665554]</strong> for more information. Glowry Stone Resort is not just a picnic spot near Ahmednagar; it’s an experience that brings families closer to nature, provides them with endless opportunities to bond, and ensures a day of relaxation, fun, and enjoyment.</p>

              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                    <p>- Best resort near Pune</p>

<p>- Luxury Resort Near Me</p>

<p>- Best Resorts in Ahmednagar</p>

<p>- Family-friendly Resorts</p>

<p>- Weekend Getaway Resort</p>

<p>- Eco-Friendly Resorts</p>

<p>- Resorts with Scenic Views</p>

<p>- Pet-Friendly Resorts</p>


                    </td>
                    <td style={{ borderWidth: "0px" }}>
                    <p>- Mountain View Resorts</p>

<p>- Resorts with Pool and Spa</p>

<p>- Romantic Resorts for Couples</p>

<p>- Budget Resorts</p>

<p>- Resorts for Corporate Retreats</p>

<p>- Adventure Resorts</p>

<p>- Nature Resorts</p>

<p>- Weekend Resorts for Couples</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>Why Choose Glowry Stone Resort Near Pune?</h2>

<p>Glowry Stone Resort, located just a short drive away from Pune, offers a unique blend of comfort, nature, and adventure, making it an ideal destination for families, couples, and corporate outings alike. Here’s why you should choose Glowry Stone Resort for your next getaway:</p>

<h2>1. Ideal Location Near Pune</h2>
<ul>
  <li><strong>Proximity to Pune:</strong> Located just 70 km from Pune, Glowry Stone Resort is a perfect escape from the hustle and bustle of the city. It’s easily accessible for weekend getaways and day trips, offering a peaceful retreat surrounded by nature.</li>
</ul>

<h2>2. Perfect for Family Getaways</h2>
<ul>
  <li><strong>Family-friendly Facilities:</strong> The resort is designed with families in mind, offering spacious rooms, a children’s play area, and numerous outdoor activities that can be enjoyed by every family member. Whether it’s enjoying a relaxing afternoon by the pool or exploring nature trails, there’s something for everyone.</li>
  <li><strong>Safe and Secure:</strong> The resort offers a secure environment for families with child-friendly amenities, allowing parents to unwind while their kids have fun.</li>
</ul>

<h2>3. Romantic Getaway for Couples</h2>
<ul>
  <li><strong>Romantic Ambience:</strong> With scenic views, nature walks, and cozy, private rooms, Glowry Stone Resort is the perfect destination for couples looking for a peaceful retreat. Whether it's a honeymoon or just a weekend break, the resort’s serene setting creates the perfect romantic escape.</li>
  <li><strong>Private Dining Options:</strong> Enjoy candlelight dinners under the stars, or have a private meal by the poolside for a truly intimate experience.</li>
</ul>

<h2>4. Adventure & Nature Enthusiasts’ Paradise</h2>
<ul>
  <li><strong>Adventure Activities:</strong> For those who love a thrill, Glowry Stone Resort offers adventure activities such as trekking, zip-lining, rock climbing, and more. Whether you’re looking for adrenaline or simply want to immerse yourself in nature, the resort provides the perfect balance.</li>
  <li><strong>Eco-Friendly Atmosphere:</strong> The resort is built to harmonize with nature, allowing guests to enjoy the surrounding lush greenery, wildlife, and clean air. It’s a true nature lover’s haven.</li>
</ul>

<h2>5. Corporate Outings and Team Building</h2>
<ul>
  <li><strong>Ideal for Corporate Retreats:</strong> Glowry Stone Resort is also perfect for corporate team outings. With conference facilities, team-building activities, and spacious outdoor areas for group bonding, the resort helps improve teamwork and provides a refreshing break from the office routine.</li>
  <li><strong>Customized Packages:</strong> We offer tailored corporate outing packages, including team-building exercises, fun activities, and catering options, ensuring your team has a memorable experience.</li>
</ul>

<h2>6. Luxurious Amenities & Services</h2>
<ul>
  <li><strong>Modern Facilities:</strong> Each room at Glowry Stone Resort is designed with comfort in mind, featuring modern amenities such as air-conditioning, Wi-Fi, in-room dining, and more. You’ll experience luxury while being in the lap of nature.</li>
  <li><strong>Swimming Pool & Spa:</strong> Relax and unwind with a dip in the resort’s pool, or treat yourself to rejuvenating spa treatments. The resort’s wellness services ensure a peaceful and revitalizing stay.</li>
</ul>

<h2>7. Pet-Friendly Resort</h2>
<ul>
  <li><strong>Bring Your Furry Friends:</strong> Glowry Stone Resort is a pet-friendly resort, meaning you can bring your pets along for your getaway. Enjoy open spaces and nature walks together with your furry companions, making your trip even more special.</li>
</ul>

<h2>8. Budget-Friendly Options</h2>
<ul>
  <li><strong>Affordable Luxury:</strong> Despite offering high-end amenities, Glowry Stone Resort offers budget-friendly pricing, making it accessible to a wide range of guests. Whether you’re looking for a luxurious stay or a more affordable option, there’s a package suited to your needs.</li>
</ul>

<h2>9. Delicious Dining Experience</h2>
<ul>
  <li><strong>Multi-Cuisine Restaurant:</strong> Savor a range of culinary delights at our multi-cuisine restaurant, serving everything from traditional Indian dishes to global favorites. We also offer outdoor dining options to enhance your experience.</li>
  <li><strong>Locally-Sourced Ingredients:</strong> The food at Glowry Stone Resort is prepared with fresh, locally-sourced ingredients to ensure quality and taste in every bite.</li>
</ul>

<h2>10. Seamless Booking and Customer Support</h2>
<ul>
  <li><strong>Easy Booking Process:</strong> Booking a stay at Glowry Stone Resort is simple through our website or by calling our customer service. We offer flexible booking options, seasonal discounts, and custom packages for group bookings and corporate events.</li>
  <li><strong>Responsive Customer Service:</strong> Our team is available to answer any questions and assist with all your booking needs, ensuring a smooth and hassle-free experience from start to finish.</li>
</ul>


              <div className="">
                <h2>
                FAQs for Glowry Stone Resort "Glowry Stone Resort Near Pune "?
                </h2>
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className={`faq-question ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {index + 1}. {faq.question}
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer">{faq.answer}</div>
                    )}
                  </div>
                ))}
              </div>

                {/* ======================== */}
                <div className="">
                  <h2>
                  Testimonials for Glowry Stone Resort Near Pune:- {" "}
                  </h2>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-slide">
                        <blockquote>
                          <p>{testimonial.quote}</p>
                          <footer>— {testimonial.author}</footer>
                        </blockquote>
                      </div>
                    ))}
                  </Slider>
                </div>

               
                <ContactSection></ContactSection>

</div>
<div class="col-12 col-md-4">
<RecentPost></RecentPost>
  <Contactbox></Contactbox>
</div> 
            </div>
          </div>
      
    </section>

  <GallerySlider></GallerySlider>

        </>
    )
}  