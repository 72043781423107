import Carousel from "./Carousel";
import React, { useState,useEffect } from "react";
import { useRef } from 'react';
import { Link } from 'react-router-dom';  
import './App.css';
import Banner from "./Banner";
import Testimonial from './Testimonial';
import Photos from "./Photos";
import { Helmet } from 'react-helmet';


import Slider from 'react-slick';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Import Swiper styles
import 'swiper/css/pagination'; // Import pagination styles
import 'swiper/css/navigation'; // Import navigation styles



export default function Home(){

  const faqs = [
    { question: 'What types of accommodations does Glowry Stone Resort offer?', answer: 'Glowry Stone Resort offers luxury tents, spacious cottages, and family-friendly rooms with modern amenities like air-conditioning and Wi-Fi.' },
    { question: 'What activities are available at the resort?', answer: 'Guests can enjoy activities such as trekking, zip-lining, ATV rides, lakeside boating, bird watching, and bonfire nights with BBQ and music.' },
    { question: 'Is the resort family-friendly?', answer: 'Yes, Glowry Stone Resort is perfect for families, offering kids’ activities like nature walks, outdoor games, and a safe, spacious environment for children.' },
    { question: 'Can I host a corporate retreat or event at Glowry Stone Resort?', answer: 'Yes, the resort offers customized packages for corporate retreats, team-building exercises, and meetings, along with plenty of outdoor space for activities.' },
    { question: 'Does Glowry Stone Resort have a swimming pool?', answer: 'Yes, the resort features a private swimming pool for guests to relax and enjoy the peaceful surroundings.' },
    { question: 'Are pets allowed at the resort?', answer: 'Unfortunately, pets are not allowed at Glowry Stone Resort to maintain a peaceful and safe environment for all guests.' },
    { question: 'How far is Glowry Stone Resort from Pune?', answer: 'Glowry Stone Resort is located just 30-40 km from Pune, making it an easy and convenient drive for a quick getaway.' },
    { question: 'Is Wi-Fi available at the resort?', answer: 'Yes, we provide free high-speed Wi-Fi across the resort to keep you connected during your stay.' },
    { question: 'Can I make a reservation at Glowry Stone Resort?', answer: 'Yes, you can easily make a reservation through our website or by calling our reservations team directly.' },
    { question: 'What is the best time to visit Glowry Stone Resort?', answer: 'The best time to visit is from October to March when the weather is cool and perfect for outdoor activities.' },
  ];
  

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle between active and inactive states
  };

  // Split the FAQ into two groups (left and right)
  const leftFaqs = faqs.slice(0, 5);
  const rightFaqs = faqs.slice(5);


    const hotelCards = [
        {
          link:'Accommodation-Near-Supa-in-Midc',
          description: "Accomodation near supa in MIDC",
          image: "glory stone images/Glowry Stone Resort keyword/Accomodation near supa in midc.jpg",
        },
        {
          link:'One-Day-Picnic-Spot-From-Mumbai',
          description: "One day picnic spot from mumbai",
          image: "glory stone images/Glowry Stone Resort keyword/One day picnic spot from mumbai.jpg",
        },
        {
          link:'Resort-Near-Mumbai',
          description: "Resort Near Mumbai",
          image: "glory stone images/Glowry Stone Resort keyword/Resort Near Mumbai.jpg",
        },
    
        {
          link:'Team-Outing-Places-Near-Mumbai',
          description: "Team outing places near mumbai",
          image: "glory stone images/Glowry Stone Resort keyword/Team outing places near mumbai.jpg",
        },
    
        {
          link:'Mango-Mahotsav-in-Ahmednagar',
          description: "Mango Mohostav in Ahmednagar",
          image: "glory stone images/Glowry Stone Resort keyword/Mango Mohostav in Ahmednagar.jpg",
        },
    
        {
          link:'Nature-resorts-near-pune-for-weekend',
          description: "Nature resort near pune",
          image: "glory stone images/Glowry Stone Resort keyword/Nature resort near pune.jpg",
        },


        {
          link:'Hurda-Party-Places-Near-Pune',
          description: "Hurda party places near pune",
          image: "glory stone images/Glowry Stone Resort keyword/Hurda party places near pune.jpg",
        },
       
       
      ];


      const containerRef = useRef(null);

      useEffect(() => {
        const scrollContainer = containerRef.current;
        let scrollAmount = 0;
        const scrollSpeed =1; // Adjust scroll speed
    
        const scroll = () => {
          if (scrollContainer) {
            scrollAmount += scrollSpeed;
            scrollContainer.scrollLeft += scrollSpeed; // Scroll by the scroll speed
    
            // Reset scroll when it reaches the end
            if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth - scrollContainer.clientWidth) {
              scrollContainer.scrollLeft = 0;
            }
          }
    
          requestAnimationFrame(scroll);
        };
    
        scroll();
    
        return () => {
          cancelAnimationFrame(scroll);
        };
      }, []);
      
      
      const TrendItem = ({ country, city, tours, image }) => (
        <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
          <div className="trend-item1">
            <div className="trend-image position-relative rounded">
              <img src={image} alt={city} />
              <div className="trend-content d-flex align-items-center justify-content-between position-absolute bottom-0 p-4 w-100 z-index">
                <div className="trend-content-title">
                  <h5 className="mb-0">
                    <a href="tour-grid.html" className="theme1">{country}</a>
                  </h5>
                  <h3 className="mb-0 white">{city}</h3>
                </div>
                {/* <span className="white bg-theme p-1 px-2 rounded">{tours} Tours</span> */}
              </div>
              <div className="color-overlay"></div>
            </div>
          </div>
        </div>
      );
      
    
        const destinations = [
          { country: 'Glowry Stone', city: 'Glowry',  image: 'glory stone images/Glowry Stone Resort/Glowry stone Resort gallery1.jpg' },
          { country: 'Glowry Stone Resort', city: 'Glowry Stone Resort',  image: 'glory stone images/Glowry Stone Resort/Glowry stone Resort gallery1.jpg' },
          { country: 'Glowry Stone Resort front gate', city: 'Japan', tours: 21, image: 'glory stone images/Glowry Stone Resort/Glowry stone resort outside.jpg' },
          { country: 'Glowry Stone Resort', city: 'Russia', tours: 15, image: 'glory stone images/Glowry Stone Resort/Glowry Stone Resort gallery8.jpg' },
          { country: 'Glowry Stone Resort', city: 'America', tours: 32, image: 'glory stone images/Glowry Stone Resort/Glowry Stone Resort gallery7.jpg' },
          { country: 'Glowry Stone Resort Swimming pool', city: 'America', tours: 32, image: 'glory stone images/Glowry Stone Resort/Glowry Stone Resort Swimming Pool.jpg' },
          
          { country: 'Glowry Stone Resort', city: 'America', tours: 32, image: ' glory stone images/Glowry Stone Resort/Glowry Stone Resort hall.jpg' },
          
         
        ];


        const [experience, setExperience] = useState(0);
        const [packages, setPackages] = useState(0);
        const [customers, setCustomers] = useState(0);
        const [awards, setAwards] = useState(0);
      
        useEffect(() => {
          // Counter animation logic
          const experienceInterval = setInterval(() => {
            if (experience < 20) setExperience(prev => prev + 1);
          }, 20);
      
          const packagesInterval = setInterval(() => {
            if (packages < 100) setPackages(prev => prev + 1);
          }, 20);
      
          const customersInterval = setInterval(() => {
            if (customers < 700) setCustomers(prev => prev + 1);
          }, 20);
      
          const awardsInterval = setInterval(() => {
            if (awards < 5) setAwards(prev => prev + 1);
          },20);
      
          // Cleanup intervals
          return () => {
            clearInterval(experienceInterval);
            clearInterval(packagesInterval);
            clearInterval(customersInterval);
            clearInterval(awardsInterval);
          };
        }, [experience, packages, customers, awards]);
      


        const cardData = [
            {
              image: "glory stone images/Glowry Stone Resort keyword/One day picnic spot near Ahmednager.jpg",
              location: "Glowry Stone Resort",
              title: " One Day Picnic Spot Near Ahmednagar for Family",
              para:'Glowry Stone Resort is your ideal destination! Nestled in the lap of nature, Glowry Stone Resort offers a perfect blend of serenity, adventure, and relaxation – making it an excellent choice for families, friends, and corporate getaways',
              path:'One-Day-Picnic-Spot-Near-Ahmednagar-for-Family'
            },
            {
              image: "glory stone images/Glowry Stone Resort keyword/one day trip near Ahmednagar.jpg",
              location: "Glowry Stone Resort",
              title: "One Day Trip Near Ahmednagar",
              para:'Glowry Stone Resort is your ideal destination! Nestled in the lap of nature, Glowry Stone Resort offers a perfect blend of serenity, adventure, and relaxation – making it an excellent choice for families, friends, and corporate getaways.',
              path:'One-Day-Trip-Near-Ahmednagar'
               
            },
            {
              image: "glory stone images/Glowry Stone Resort keyword/picnic spot near Ahmednagar.jpg",
              location: "Glowry Stone Resort",
              title: "Picnic Spot Near Ahmednagar",
              para:'When it comes to hotel booking for corporate stays in Viman Nagar, Grand Legacy inn hotels stands out as the ideal choice for business professionals seeking comfort, convenience, and exceptional service.',
              path:'Picnic-Spot-Near-Ahmednagar'
               
            },
            {
              
              image: "glory stone images/Glowry Stone Resort keyword/Hurda Party place near Ahmednagar.jpg",
              location: "Glowry Stone Resort",
              title: "Hurda Party Places Near Ahmednagar",
              para:'Glowry Stone Resort offers the perfect setting for a Hurda Party near Ahmednagar. If you are looking for a memorable and fun-filled experience, Glowry Stone Resort is the ideal choice for hosting your Hurda Party.',
              path:'Hurda-Party-Places-Near-Ahmednagar'
               
            },
            {
              
              image: "glory stone images/Glowry Stone Resort keyword/Glowry Stone Resort near pune.jpg",
              location: "Glowry Stone Resort",
              title: "Glowry Stone Resort Near Pune",
              para:'Nestled in the lap of nature, just a short drive away from Pune, this charming resort offers an ideal blend of relaxation, adventure, and team-building opportunities, making it a top choice for family getaways, corporate outings, and weekend retreats.',
              path:'Glowry-Stone-Resort-Near-Pune'
               
            },
            {
              
              image: "glory stone images/Glowry Stone Resort keyword/Water park near Ahmednagar.jpg",
              location: "Glowry Stone Resort",
              title: "Water Park Near Ahmednagar",
              para:'Glowry Stone Resort is the ultimate water park near Ahmednagar, offering a day full of excitement, adventure, and relaxation. Whether you’re looking for an adrenaline rush or a peaceful retreat, our resort features a range of water-based activities that will make your day unforgettable.',
              path:'Water-Park-Near-Ahmednagar'
               
            },
            {
              
              image: "glory stone images/Glowry Stone Resort keyword/Mango Mohostav in Ahmednagar.jpg",
              location: "Glowry Stone Resort",
              title: "Mango Mahotsav in Ahmednagar",
              para:' Nestled in the picturesque surroundings of Ahmednagar, Glowry Stone Resort combines luxury with nature, providing a perfect escape for travelers who want to enjoy the Mango Mahotsav while also soaking in the tranquility of the great outdoors.',
              path:'Mango-Mahotsav-in-Ahmednagar'
               
            },
            
          ];
          
            // Slick carousel settings
            const settings = {
              dots: true,
              infinite: true,
              speed: 200,
              slidesToShow: 3,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: 2000,
              responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ],
            };


         // Handle video button click to play the video (optional)
                const handlePlayButtonClick = (videoId) => {
            // You could implement a modal or video player here using a library like react-player.
                  // For simplicity, this is just a placeholder function.
                  window.open(`https://vimeo.com/${videoId}`, '_blank');
                };


                const offers = [
   
                  {
                    id: 2,
                    image: 'glory stone images/Glowry Stone Resort Activity/Bird Watching.jpg',
                    title: 'Nature Walks and Bird Watching for Nature Lovers',
                    days: 'Glowry Stone Resort',
                    para:'Immerse yourself in the natural beauty of the resort with peaceful nature walks. As you stroll through verdant landscapes, youll have the opportunity to spot a variety of birds and wildlife. Ideal for nature enthusiasts, this activity offers a chance to reconnect with the environment and appreciate its serene beauty.'
                  },
                  {
                    id: 2,
                    image: 'glory stone images/Glowry Stone Resort Activity/Zip Line.jpg',
                    title: 'Zip-lining and Rock Climbing for Adventure Seekers',
                    days: 'Glowry Stone Resort',
                    para:'If youre seeking an adrenaline rush, the resort’s zip-lining and rock climbing activities will give you just that! Soar across the landscape on a thrilling zip-line or challenge yourself with a rock climbing session, both offering unique ways to experience the resort’s stunning natural surroundings from new heights.'
                  },
              
                  {
                    id: 2,
                    image: 'glory stone images/Glowry Stone Resort Activity/Campfire.jpg',
                    title: 'Bonfire Nights with BBQ and Music for Cozy Evenings',
                    days: 'Glowry Stone Resort',
                    para:'End your day with a cozy bonfire under the stars. Gather around with friends or family, indulge in a delicious BBQ, and enjoy music or storytelling by the fire. The bonfire nights at Glowry Stone Resort are a memorable experience that brings people together and creates lasting memories.'
                  },
               
                ];
              

                    const articles = [
                        {
                          id: 1,
                          category: 'Technology',
                          title: 'How a developer duo at Deutsche Bank keep remote alive.',
                          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.',
                          author: 'Sollmond Nell',
                          authorImage: 'images/reviewer/2.jpg',
                          image: 'images/trending/trending10.jpg',
                          link: 'detail-1.html',
                        },
                        {
                          id: 2,
                          category: 'Inspiration',
                          title: 'Inspire Runner with Autism Graces of Women\'s Running',
                          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.',
                          author: 'David Scott',
                          authorImage: 'images/reviewer/1.jpg',
                          image: 'images/trending/trending12.jpg',
                          link: 'detail-1.html',
                        },
                        {
                          id: 3,
                          category: 'Public',
                          title: 'Services To Grow Your Business Sell Affiliate Products',
                          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.',
                          author: 'John Bolden',
                          authorImage: 'images/reviewer/3.jpg',
                          image: 'images/trending/trending13.jpg',
                          link: 'detail-1.html',
                        },
                      ];
                    

                      const teamMembers = [
                        {
                          id: 1,
                          name: 'Paneer tikka masala',
                          role: 'Glowry Stone Resort',
                          image: 'glory stone images/Glowry Stone Resort/Paneer tikka masala.jpg',
                        },
                        {
                          id: 2,
                          name: 'Gulabjam dish',
                          role: 'Glowry Stone Resort',
                          image: 'glory stone images/Glowry Stone Resort/Gulabjam dish.jpg',
                        },
                        {
                          id: 3,
                          name: 'Veg pulao',
                          role: 'Glowry Stone Resort',
                          image: 'glory stone images/Glowry Stone Resort/Veg pulao.jpg',
                        },
                        {
                          id: 4,
                          name: 'Tomato soup',
                          role: 'Glowry Stone Resort',
                          image: 'glory stone images/Glowry Stone Resort/Tomato soup.jpg',
                        },
                
                      ];

                      
                

    return(
        <>

<Helmet>
        <title>Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - Home | One Day Picnic Spot Near Ahmednagar | Corporate Outing Resorts Near Pune</title>
        <meta name="description" content="Glowry Stone Resort, located near Ahmednagar, Maharashtra, offers an ideal destination for a one-day picnic, family outings, and corporate retreats. Book your stay for a perfect getaway from Pune." />
        <meta name="keywords" content="One Day Picnic Spot Near Ahmednagar, One Day Trip Near Ahmednagar, Picnic Spot Near Ahmednagar, Picnic Destination Near Pune, Corporate Outing Resorts Near Ahmednagar, Glowry Stone Resort Near Pune, Family Picnic, Weekend Getaway Near Pune, Resorts Near Pune" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact: +91 7066665554 / +91 9145557771 | Home | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar" />
        <meta property="og:description" content="Glowry Stone Resort offers a perfect family-friendly picnic destination near Ahmednagar, ideal for one-day trips and corporate outings. Located just a short drive from Pune." />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar" />
        <meta name="twitter:description" content="Glowry Stone Resort, a serene retreat near Ahmednagar, offers family picnics, corporate outings, and more. Book your escape from Pune now!" />
        <meta name="twitter:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Resort",
              "name": "Glowry Stone Resort",
              "url": "https://www.glowrystone.com",
              "logo": "https://www.glowrystone.com/logo.jpg",  // Replace with your logo URL
              "image": "https://www.glowrystone.com/ogimage.jpg", // Replace with your image URL
              "description": "Glowry Stone Resort is an ideal destination for a family-friendly picnic, one-day trips, and corporate outings near Ahmednagar and Pune. It offers serene surroundings, leisure activities, and a memorable experience.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road, Tal-.Parner, Dist- Ahilya Nagar",
                "addressLocality": "Ahmednagar",
                "addressRegion": "Maharashtra",
                "postalCode": "414302",
                "addressCountry": "India"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 7066665554",
                "telephone": "+91 9145557771",
                "contactType": "Customer Service",
                "areaServed": "IN",
                "availableLanguage": "English"
              },
              "sameAs": [
                "https://www.facebook.com/glowrystone", // Replace with your social media URL if applicable
                "https://twitter.com/glowrystone", // Replace with your social media URL if applicable
                "https://www.instagram.com/glowrystone" // Replace with your social media URL if applicable
              ],
              "offers": {
                "@type": "Offer",
                "url": "https://www.glowrystone.com/book-now",
                "priceCurrency": "INR",
                "price": "1500",  // Set the starting price for your resort booking (if applicable)
                "eligibleRegion": {
                  "@type": "Place",
                  "name": "India"
                },
                "priceValidUntil": "2024-12-31",
                "priceSpecification": {
                  "@type": "PriceSpecification",
                  "price": "1500",
                  "priceCurrency": "INR",
                  "priceValidUntil": "2024-12-31"
                }
              },
              "telephone": "+91 7066665554 / +91 9145557771",
              "email": "reservation@glowrystone.com",
              "sameAs": [
                "https://www.glowrystone.com"
              ],
              "openingHours": "Mo-Su 08:00-20:00",  // Adjust based on your resort's operating hours
              "additionalType": "http://www.productontology.org/id/Resort"
            }
                starRating: {
      "@type": "Rating",
      bestRating: "4.9",
      ratingValue: "2",
      worstRating: "1.1",
    },
          `}
        </script>
      </Helmet>

          {/* <Carousel/> */}
          <Banner/>
          <div className="hotel-section">
      <div className="hotel-cards-container">
        {hotelCards.map((hotel, index) => (
          <div className="hotel-card" key={index}>
            <img src={hotel.image} alt={hotel.name} className="hotel-image" />
            <Link to={hotel.link}><p>{hotel.description}</p></Link>
          </div>
        ))}
        {/* Duplicate the cards to create a looping effect */}
        {hotelCards.map((hotel, index) => (
          <div className="hotel-card" key={`duplicate-${index}`}>
            <img src={hotel.image} alt={hotel.name} className="hotel-image" />
            <Link to={hotel.link}><p>{hotel.description}</p></Link>
            
          </div>
        ))}
      </div>
    </div>

    {/* section second */}

    <section
      className="about-us pb-6 pt-6"
      style={{ backgroundImage: 'url(images/shape4.png)', backgroundPosition: 'center' }}
    >
      <div className="container">
        <div className="section-title mb-6 w-50 mx-auto text-center">
          <h4 className="mb-1 theme1">3 Steps to the Perfect Tour with Glowry Stone Resort</h4>
          <h2 className="mb-1">
            Find <span className="theme">Your Perfect Escape</span>
          </h2>
          <p>At Glowry Stone Resort, we believe that a perfect vacation begins with understanding your needs. Whether you're looking for a peaceful getaway, an adventure in nature, or a wellness retreat, we guide you every step of the way. </p>
        </div>

        {/* why us starts */}
        <div className="why-us">
          <div className="why-us-box">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
                <div className="why-us-item text-center p-4 py-5  rounded bg-white">
                  <div className="why-us-content">
                    <div className="why-us-icon">
                      <i className="icon-flag theme"></i>
                    </div>
                    <h4>
                         <p>Discover Your Dream Destination</p>
                    </h4>
                    <p className="mb-0">At Glowry Stone Resort, your journey begins with discovering your dream destination. Whether you seek serene lakeside relaxation, thrilling mountain treks, or a holistic wellness retreat, we help you envision your perfect escape. </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
                <div className="why-us-item text-center p-4 py-5  rounded bg-white">
                  <div className="why-us-content">
                    <div className="why-us-icon">
                      <i className="icon-location-pin theme"></i>
                    </div>
                    <h4>
                       <p> Choose Your Ideal Activitie</p>
                    </h4>
                    <p className="mb-0">Whether you're an adventure seeker or a lover of peace and relaxation, Glowry Stone Resort offers a wide range of curated experiences. From tranquil nature walks and scenic hikes to rejuvenating spa therapies and yoga retreats, you tell us what you want to do</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
                <div className="why-us-item text-center p-4 py-5  rounded bg-white">
                  <div className="why-us-content">
                    <div className="why-us-icon">
                      <i className="icon-directions theme"></i>
                    </div>
                    <h4>
                      <a href="about.html"> Personalize Your Stay</a>
                    </h4>
                    <p className="mb-0">Your experience is as unique as you are, and at Glowry Stone Resort, we take pride in tailoring your stay to your preferences. From the type of accommodations you prefer to the local experiences you'd like to immerse yourself in, we ensure that every detail of your stay is personalized</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                <div className="why-us-item text-center p-4 py-5 border rounded bg-white">
                  <div className="why-us-content">
                    <div className="why-us-icon">
                      <i className="icon-compass theme"></i>
                    </div>
                    <h4>
                      <a href="about.html">We are 100% Trusted Tour Agency</a>
                    </h4>
                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing.</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* why us ends */}
      </div>
      <div className="white-overlay"></div>
    </section>


    {/* ================= top destination start ============== */}
    <section className="trending pb-5 pt-0">
      <div className="container">
        <div className="section-title mb-6 w-50 mx-auto text-center">
          <h4 className="mb-1 theme1">Top Resort</h4>
          <h2 className="mb-1">Explore <span className="theme">Glowry Stone Resort</span></h2>
          <p>
          Glowry Stone Resort is a serene, eco-friendly retreat near Pune, offering a perfect blend of nature, adventure, and luxury for a memorable getaway.
          </p>
        </div>

        <div className="row align-item-center">
          {/* First destination item */}
          <div className="col-lg-5 mb-4 img1box">
           
            <img src="glory stone images/Glowry Stone Resort/glowry stone home page.jpg" alt="Glowry Stone Resort"/>
          </div>

          {/* Remaining destinations in the row */}
          <div className="col-lg-7">
            <div className="row">
              {destinations.slice(1).map((dest, index) => (
                <TrendItem
                  key={index}
                  country={dest.country}
                  // city={dest.city}
                  // tours={dest.tours}
                  image={dest.image}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>


    {/* ==================== section fourth ================== */}
    <section
      className="about-us pt-0"
      style={{ backgroundImage: 'url(images/bg/bg-trans.png)' }}
    >
      <div className="container">
        <div className="about-image-box">
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col-lg-6 mb-4 pe-4">
              <div className="about-image overflow-hidden">
                <img src="glory stone images/Glowry Stone Resort/Glowry stone resort galler1.jpg" alt="Glowry Stone Resort" />
              </div>
          
            </div>
            <div className="col-lg-6 mb-4 ps-4">
              <div className="about-content text-center text-lg-start mb-4">
                <h4 className="theme d-inline-block mb-0">Get To Know Us</h4>
                <h2 className="border-b mb-2 pb-1">Explore Nature and Adventure at Glowry Stone Resort</h2>
                <p className="border-b mb-2 pb-4">
                At Glowry Stone Resort, we invite you to experience the beauty of nature and the thrill of adventure, all while enjoying the comforts of a luxurious retreat. Located just outside Pune, in the lap of scenic hills and serene landscapes, our resort offers a perfect escape from the everyday hustle. Whether you’re looking for a peaceful retreat, an adventurous getaway, or a memorable family vacation, Glowry Stone Resort is your destination for rejuvenation and adventure.
                  <br />
                  <br />
 
                </p>
                <div className="about-listing">
                
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              {/* Counter */}
              <div className="counter-main w-75 float-end">
                <div className="counter p-4 pb-0 box-shadow bg-white rounded">
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                      <div className="counter-item border-end pe-4">
                        <div className="counter-content">
                          <h2 className="value mb-0 theme">{experience}+</h2>
                          <span className="m-0">Hospitality Excellence</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                      <div className="counter-item border-end pe-4">
                        <div className="counter-content">
                          <h2 className="value mb-0 theme">{packages}+</h2>
                          <span className="m-0">Adventure & Nature Activities</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                      <div className="counter-item border-end pe-4">
                        <div className="counter-content">
                          <h2 className="value mb-0 theme">{customers}+</h2>
                          <span className="m-0">Happy Guests Every Year</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                      <div className="counter-item">
                        <div className="counter-content">
                          <h2 className="value mb-0 theme">{awards} <i class="bi bi-star-fill"></i></h2>
                          <span className="m-0">Service & Guest Reviews</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Counter */}
            </div>
          </div>
        </div>
      </div>
      <div className="white-overlay"></div>
    </section>


    {/* ====================== */}
    <section className="trending pb-0">
      <div className="container">
        <div className="row align-items-center justify-content-between mb-6">
          <div className="col-lg-7">
            <div className="section-title text-center text-lg-start">
              <h4 className="mb-1 theme1">Top resort</h4>
              <h2 className="mb-1">
                Best <span className="theme">Services</span>
              </h2>
              <p>
Glowry Stone Resort is a tranquil nature retreat near Pune, offering a blend of adventure, relaxation, and eco-friendly luxury amidst scenic landscapes.
              </p>
            </div>
          </div>
        </div>
        <div className="trend-box">
          <Slider {...settings}>
            {cardData.map((item, index) => (
              <div className="col-lg-4 col-md-6 col-sm-6 mb-4 " key={index}>
                <div className="trend-item rounded  service-box">
                  <div className="trend-image position-relative">
                    <img src={item.image} alt={item.title} />
                    <div className="color-overlay"></div>
                  </div>
                  <div className="trend-content p-4 pt-5 position-relative">
                   
                    <h5 className="theme mb-1">
                      <i className="flaticon-location-pin"></i> {item.location}
                    </h5>
                    <h3 className="mb-1">
                      <a href="tour-grid.html">{item.title}</a>
                    </h3>
                    {/* <div className="rating-main d-flex align-items-center pb-2">
                      <div className="rating">
                        {[...Array(item.rating)].map((_, i) => (
                          <span key={i} className="fa fa-star checked"></span>
                        ))}
                      </div>
                      <span className="ms-2">({item.reviews})</span>
                    </div> */}
                    <p className="border-b pb-2 mb-2">
                      {item.para}
                    
                    </p>
                    <div className="entry-meta">
                      <div className="entry-author d-flex align-items-center">
                        <p className="mb-0">
                          {/* <span className="theme fw-bold fs-5"> {item.price}</span> | Per person */}
                          <Link to={item.path} class="book-now-button">Read More...</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>

    {/* ================= Explore section=============== */}

    {/* <section
      className="discount-action pt-6"
      style={{ backgroundImage: 'url(images/section-bg1.png)', backgroundPosition: 'center' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="container">
        <div className="call-banner rounded pt-10 pb-14">
          <div className="call-banner-inner w-75 mx-auto text-center px-5">
            <div className="trend-content-main">
              <div className="trend-content mb-5 pb-2 px-5">
                <h5 className="mb-1 theme">Escape to Serenity</h5>
                <h2>
                  <a href="detail-fullwidth.html">
                 Rejuvenate Your Soul, <span className="theme1">Experience Nature Like Never Before!</span>
                  </a>
                </h2>
                <p>
                Nestled amidst the breathtaking hills and tranquil landscapes, Glowry Stone Resort offers the perfect blend of adventure, relaxation, and luxury. 
                </p>
              </div>
              <div className="video-button text-center position-relative">
                <div className="call-button text-center">
                  <button
                    type="button"
                    className="play-btn js-video-button"
                    onClick={() => handlePlayButtonClick('870769596')} // Using the video ID from the data attribute
                  >
                    <i className="fa fa-play bg-blue"></i>
                  </button>
                </div>
                <div className="video-figure"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="white-overlay"></div>
    </section> */}

<section
  className="discount-action pt-6"
  style={{ backgroundImage: 'url(images/section-bg1.png)', backgroundPosition: 'center' }}
>
  <div
    className="section-shape section-shape1 top-inherit bottom-0"
    style={{ backgroundImage: 'url(images/shape8.png)' }}
  ></div>
  <div className="container">
    <div className="call-banner rounded pt-10 pb-14">
      <div className="call-banner-inner w-75 mx-auto text-center px-5">
        <div className="trend-content-main">
          <div className="trend-content mb-5 pb-2 px-5">
            <h5 className="mb-1 theme">Escape to Serenity</h5>
            <h2>
              <a href="detail-fullwidth.html">
                Rejuvenate Your Soul, <span className="theme1">Experience Nature Like Never Before!</span>
              </a>
            </h2>
            <p>
              Nestled amidst the breathtaking hills and tranquil landscapes, Glowry Stone Resort offers the perfect blend of adventure, relaxation, and luxury.
            </p>
          </div>
          
          {/* Video Embed Section */}
          <div className="video-button text-center position-relative">
            {/* <div className="call-button text-center">
              <button
                type="button"
                className="play-btn js-video-button"
                // Add logic for video play if needed
              >
                <i className="fa fa-play bg-blue"></i>
              </button>
            </div> */}
            <div className="video-figure">
              {/* Responsive YouTube Video Embed */}
              <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', width: '100%' }}>
              <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', width: '100%' }}>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/0nrAMiFXLe4?si=MdvJg9lXpG-bGS9G"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
      ></iframe>
    </div>
              </div>
            </div>
          </div>
          {/* End of Video Embed Section */}
        </div>
      </div>
    </div>
  </div>
  <div className="white-overlay"></div>
</section>



    {/* ===============  */}

    <section className="trending pb-0 pt-4">
      <div className="container">
        <div className="section-title mb-6 w-75 mx-auto text-center">
          <h4 className="mb-1 theme1">We Provide our customers..</h4>
          <h2 className="mb-1">
            <span className="theme">Best Activities</span> 
          </h2>
        
        </div>
        <div className="trend-box">
          <div className="row">
            {offers.map((offer) => (
              <div key={offer.id} className="col-lg-4 col-md-6 col-sm-6 mb-4">
                <div className="trend-item rounded box-shadow bg-white activity-box1">
                  <div className="trend-image position-relative">
                    <img src={offer.image} alt="image" />
                    <div className="ribbon ribbon-top-left">
                      <span className="fw-bold">{offer.discount}</span>
                    </div>
                    <div className="color-overlay"></div>
                  </div>
                  <div className="trend-content p-4 pt-5 position-relative">
                    <div className="trend-meta bg-theme white px-3 py-2 rounded">
                      <div className="entry-author">
                        <i className="icon-calendar"></i>
                        <span className="fw-bold"> {offer.days}</span>
                      </div>
                    </div>
                    <h5 className="theme mb-1">
                      <i className="flaticon-location-pin"></i> {offer.country}
                    </h5>
                    <h3 className="mb-1">
                      <a href="tour-grid.html">{offer.title}</a>
                    </h3>
              
                    <p className="border-b pb-2 mb-2">
                     {offer.para}
                    </p>
                    <div className="entry-meta">
                      <div className="entry-author d-flex align-items-center">
                        <p className="mb-0 text-center">
                        <Link to='/contact' class="">Book Now <i className="fa fa-arrow-right"></i></Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>


    <section className="our-team pb-6">
      <div className="container">
        <div className="section-title mb-6 w-75 mx-auto text-center">
          <h4 className="mb-1 theme1">Top Resort</h4>
          <h2 className="mb-1">Our <span className="theme">Gallery</span></h2>
           <p>Glowry Stone Resort is a serene and luxurious getaway nestled amidst nature's beauty, offering guests a perfect blend of relaxation, adventure, and comfort.</p>
        </div>

        <div className="team-main">
         

<Photos></Photos>
        </div>
      </div>
    </section>




    <section className="trending recent-articles pb-6">
      <div className="container">
       
          <h4 className="mb-1 theme1">Glowry Stone Resort</h4>
        <h2 className="mb-1">Special <span className="theme"> Dishes</span></h2>
          <div className="row shop-slider">
            {teamMembers.map((member) => (
              <div key={member.id} className="col-lg-3 col-md-6 col-sm-12 mb-4">
                <div className="team-list rounded">
                  <div className="team-image">
                    <img src={member.image} alt={member.name} />
                  </div>
                  <div className="team-content text-center p-3 bg-theme">
                    <h4 className="mb-0 white">{member.name}</h4>
                    <p className="mb-0 white">{member.role}</p>
                  </div>
                </div>
              </div>
            ))}
          </div> 
      </div>
    </section>


  <Testimonial></Testimonial>


  <section className="trending recent-articles pb-6">
     <div className="container">
      <div className="faq-container">
        <h2 className="pb-5 text-center fw-bold">FAQs for <span className="theme">Glowry Stone Resort </span> ? </h2>

        <div className="faq-grid">
          <div className="faq-column">
            {leftFaqs.map((faq, index) => (
              <div className="accordion-item accordian-bg" key={index}>
                <div
                  className={`accordion-header ${activeIndex === index ? 'active' : ''}`}
                  onClick={() => toggleAccordion(index)}
                >
                  {faq.question}
                  <span className="accordion-icon">{activeIndex === index ? '-' : '+'}</span>
                </div>
                <div
                  className="accordion-content"
                  style={{
                    maxHeight: activeIndex === index ? '200px' : '0',
                    overflow: 'hidden',
                    transition: 'max-height 0.3s ease',
                  }}
                >
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="faq-column">
        
            {rightFaqs.map((faq, index) => (
              <div className="accordion-item accordian-bg" key={index + 5}>
                <div
                  className={`accordion-header ${activeIndex === index + 5 ? 'active' : ''}`}
                  onClick={() => toggleAccordion(index + 5)}
                >
                  {faq.question}
                  <span className="accordion-icon">{activeIndex === index + 5 ? '-' : '+'}</span>
                </div>
                <div
                  className="accordion-content"
                  style={{
                    maxHeight: activeIndex === index + 5 ? '200px' : '0',
                    overflow: 'hidden',
                    transition: 'max-height 0.3s ease',
                  }}
                >
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    </section>
    
    

   

    
    

        </>
    )
}