import Slider from "react-slick";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import './Page.css';
import GallerySlider from "./GallerySlider";
import Contactbox from "./Contactbox";
import RecentPost from "./RecentPost";
import Contact from "./Contact";
import ContactSection from "./ContactSection";
import { Helmet } from 'react-helmet';


export default function OnedayPicnicSpotinMumbai(){

    const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
        question: "How far is Glowry Stone Resort from Mumbai?",
        answer: "Glowry Stone Resort is located approximately 3-4 hours away from Mumbai, making it an ideal one-day picnic spot. Its convenient location allows visitors to enjoy a quick and hassle-free getaway."
    },
    {
        question: "What activities are available at Glowry Stone Resort for a one-day picnic?",
        answer: "At Glowry Stone Resort, you can enjoy a wide range of activities such as boating, nature walks, cycling, zip-lining, rappelling, and ATV rides. The resort also offers team-building games and outdoor challenges for families, friends, and corporate outings."
    },
    {
        question: "Is Glowry Stone Resort suitable for family picnics?",
        answer: "Yes! Glowry Stone Resort is perfect for family picnics. The resort offers family-friendly activities such as boating, picnics by the lake, nature trails, and fun outdoor games. It’s a great place to bond with your loved ones in a serene and beautiful environment."
    },
    {
        question: "Can couples visit Glowry Stone Resort for a day trip?",
        answer: "Absolutely! Glowry Stone Resort is an excellent romantic getaway for couples. Couples can enjoy peaceful boat rides, nature walks, and bonfire evenings. The resort’s serene ambiance provides a perfect setting for relaxation and intimacy."
    },
    {
        question: "Are the activities at Glowry Stone Resort suitable for children?",
        answer: "Yes! Glowry Stone Resort is a great choice for families with children. The resort offers kid-friendly activities like cycling, treasure hunts, boating, and nature walks. Children can also enjoy outdoor games and a safe environment to explore."
    },
    {
        question: "Is lunch provided at the resort during a one-day picnic?",
        answer: "Yes, Glowry Stone Resort provides delicious meals as part of their day picnic packages. Guests can enjoy freshly prepared local and continental dishes. You can also opt for special picnic-style meals by the lakeside or in scenic areas of the resort."
    },
    {
        question: "Is there parking available at Glowry Stone Resort?",
        answer: "Yes, Glowry Stone Resort has ample parking space for guests arriving by car. The resort provides secure parking to ensure your vehicle is safe while you enjoy your day trip."
    },
    {
        question: "What is the best time to visit Glowry Stone Resort for a one-day picnic?",
        answer: "The resort is open year-round, but the best time to visit is during the monsoon and winter seasons, when the weather is pleasant, and the natural beauty of the resort is at its peak. The resort is especially popular during the monsoon season, as the lush green landscape and cool weather create a refreshing experience."
    },
    {
        question: "Can we book Glowry Stone Resort for a one-day picnic in advance?",
        answer: "Yes, it is highly recommended to book in advance to secure your spot, especially during peak seasons like holidays or weekends. You can contact the resort directly or book online to ensure availability."
    },
    {
        question: "What makes Glowry Stone Resort a unique one-day picnic spot from Mumbai?",
        answer: "Glowry Stone Resort offers a unique blend of adventure, nature, and relaxation. With activities ranging from adventure sports to peaceful nature walks, and a beautiful, tranquil setting just a few hours away from Mumbai, it’s the perfect escape for a day trip. The resort is designed to offer something for everyone—whether you’re looking to relax, bond with family, or indulge in thrilling outdoor activities."
    }
];

const testimonials = [
    {
        quote: "We had an amazing time at Glowry Stone Resort for a one-day picnic from Mumbai! The resort is just a short drive away, which made it super convenient for us. We took a boat ride by the serene lake, went on a nature walk, and the kids had a blast with the outdoor games. The staff was friendly and attentive, and the food was delicious. It was the perfect day out, and we all felt rejuvenated by the end of it. I highly recommend Glowry Stone Resort for anyone looking for a peaceful yet adventurous family day trip!",
        author: "— Mr. Rajesh & Family"
    },
    {
        quote: "My partner and I decided to spend a day at Glowry Stone Resort for a one-day picnic, and it was the best decision we made. We enjoyed a quiet boat ride on the lake, followed by a scenic nature walk. The place is so peaceful, surrounded by lush greenery—it was the ideal romantic getaway. We ended our day with a cozy bonfire under the stars. The resort’s staff made sure everything was perfect, and we truly felt like we were in a different world, far away from the city. Highly recommend it for couples looking for a relaxing yet exciting day trip near Mumbai.",
        author: "— Mr. Neha & Amit"
    }
];

  
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
    };
  
   
    const schemaData = {
      "@context": "https://schema.org",
      "@type": "Resort",
      "name": "Glowry Stone Resort Near Mumbai | Best One Day Picnic Spot",
      "image": "https://www.glowrystone.com/images/picnic-resort-near-mumbai.jpg", // Replace with actual image URL
      "description": "Glowry Stone Resort near Mumbai is the perfect one-day picnic spot. Ideal for families, couples, or small groups looking for a relaxing and adventurous escape from the city. Enjoy activities, scenic views, and a serene environment for a memorable day trip. Book your stay for an unforgettable experience!",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road",
        "addressLocality": "Parner",
        "addressRegion": "Maharashtra",
        "postalCode": "414302",
        "addressCountry": "IN"
      },
      "url": "https://www.glowrystone.com/one-day-picnic-spot-from-mumbai",
      "sameAs": [
        "https://www.facebook.com/glowrystone",
        "https://www.instagram.com/glowrystone"
      ],
      "offers": {
        "@type": "Offer",
        "url": "https://www.glowrystone.com",
        "priceCurrency": "INR",
        "price": "Starting from INR 400",
        "eligibleRegion": "IN",
        "eligibleQuantity": {
          "@type": "QuantitativeValue",
          "value": 50,
          "unitCode": "C62"
        }
      },
      "performer": {
        "@type": "Organization",
        "name": "Glowry Stone Resort",
        "sameAs": "https://www.glowrystone.com"
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.8", // Replace with your actual average rating
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": "150" // Replace with actual rating count
      },
      "reviews": [
        {
          "@type": "Review",
          "author": {
            "@type": "Person",
            "name": "John Doe" // Replace with actual reviewer name
          },
          "datePublished": "2024-01-01",
          "reviewBody": "An amazing experience! Glowry Stone Resort offers the perfect getaway with scenic views, great activities, and a peaceful environment. Highly recommend for a day trip!",
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5",
            "bestRating": "5"
          }
        },
        {
          "@type": "Review",
      
          "datePublished": "2024-01-05",
          "reviewBody": "Had a wonderful time with family. Great location, nice activities, and well-maintained resort. Definitely worth visiting for a day trip.",
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": "4.7",
            "bestRating": "5"
          }
        }
      ]
    };
    
  
    return(
        <>
          <Helmet>
      {/* Title Tag */}
      <title>
      Contact: +91 7066665554 / +91 9145557771 |  Glowry Stone Resort | Best One Day Picnic Spot Near Mumbai | Family-Friendly Picnic Resort
      </title>

      {/* Meta Tags */}
      <meta
        name="description"
        content="Glowry Stone Resort near Mumbai offers the best one-day picnic spot for families, couples, and groups. Enjoy scenic views, fun activities, and a peaceful environment for a day trip from Mumbai."
      />
      <meta
        name="keywords"
        content="one day picnic resorts near Mumbai, one day picnic in Mumbai, one day picnic spots near Mumbai for family, day picnic near Mumbai, 1 day picnic spot in Mumbai, 1 day picnic for couples, picnic resorts near Mumbai, day picnic resort near Mumbai, 1 day return trip near Mumbai"
      />
      <meta name="author" content="Glowry Stone Resort" />
      <meta name="robots" content="index, follow" />

      {/* Open Graph for Social Sharing */}
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Glowry Stone Resort | Best One Day Picnic Spot Near Mumbai"
      />
      <meta
        property="og:description"
        content="Glowry Stone Resort near Mumbai is the perfect one-day picnic spot. Perfect for families, couples, or small groups, it offers scenic views, activities, and a peaceful escape."
      />
      <meta property="og:url" content="https://www.glowrystone.com/one-day-picnic-spot-from-mumbai" />
      <meta property="og:image" content="https://www.glowrystone.com/images/picnic-resort-near-mumbai.jpg" />
      <meta property="og:site_name" content="Glowry Stone Resort" />

      {/* Contact Information */}
      <meta
        name="contact"
        content="For reservations, contact Glowry Stone Resort at +91 7066665554 or +91 9145557771. Email: reservation@glowrystone.com"
      />
      <meta
        name="address"
        content="Glowry Stone Resort, Wadegavhan, Ahmednagar-Nagar Road, Tal. Parner, Dist. Ahilya Nagar (Ahmednagar), Maharashtra - 414302, India"
      />
      <meta name="email" content="reservation@glowrystone.com" />
      <meta name="phone" content="+91 7066665554, +91 9145557771" />

      {/* Canonical Link */}
      <link rel="canonical" href="https://www.glowrystone.com/one-day-picnic-spot-from-mumbai" />

      {/* JSON-LD Schema Markup */}
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
    <section
      className="breadcrumb-main pb-20 pt-14"
      style={{ backgroundImage: 'url(images/bg/bg1.jpg)' }}
    >
      <div
        className="section-shape section-shape1 top-inherit bottom-0"
        style={{ backgroundImage: 'url(images/shape8.png)' }}
      ></div>
      <div className="breadcrumb-outer">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h2 className="mb-3 text-white">One Day Picnic Spot from Mumbai</h2>
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to='/'>Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About Us
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="dot-overlay"></div>
    </section>


    {/* ========================= */}

    <section className="trending recent-articles pb-6 keywordsection">
      <div className="container">
      <div className="row d-flex justify-content-around">
            <div className="col-12 col-md-8">
              <div className="keyword-img">
                <img src="glory stone images/Glowry Stone Resort keyword/One day picnic spot from mumbai.jpg" className="img-fluid" alt="One day picnic spot from mumbai" />
              </div>
              <h2>Glowry Stone Resort: The Perfect One-Day Picnic Spot from Mumbai</h2>
<p>Glowry Stone Resort is the ultimate destination for a quick escape from the city's hustle and bustle. Situated just a few hours away from Mumbai, Glowry Stone Resort offers a perfect combination of adventure, nature, and relaxation for a memorable one-day trip with family, friends, or colleagues. Whether you're seeking fun-filled activities or a tranquil retreat amidst nature, Glowry Stone Resort has something for everyone.</p>

<h3>Perfect for Families: One Day Picnic Spots Near Mumbai</h3>
<p>If you're looking for a one-day picnic spot near Mumbai for family, Glowry Stone Resort offers the perfect setting. With a variety of family-friendly activities like boating, nature walks, team-building games, and outdoor sports, it’s the perfect place to spend quality time with loved ones. The resort’s peaceful atmosphere and beautiful natural surroundings ensure a relaxing yet exciting day for everyone. Whether your kids enjoy cricket, cycling, or exploring nature, they will have a blast here. The resort also offers safe play areas for younger children, making it a secure and enjoyable environment for the entire family.</p>

<h3>One Day Picnic Near Mumbai for Couples</h3>
<p>For couples looking for a 1-day picnic near Mumbai, Glowry Stone Resort provides the perfect romantic setting. Surrounded by lush greenery and tranquil lakes, the resort offers the ideal environment for relaxation and connection. Enjoy a boat ride on the serene lake, take a nature walk through the scenic trails, or simply relax and unwind by the bonfire in the evening. The peaceful ambiance and beautiful surroundings create the perfect backdrop for a memorable day out with your loved one.</p>

<h3>Day Picnic Resorts Near Mumbai for Groups</h3>
<p>Whether you're visiting with friends, colleagues, or a large group, Glowry Stone Resort is an excellent choice for a day picnic near Mumbai. The resort offers a wide range of outdoor activities that cater to all interests, from team-building exercises and treasure hunts to thrilling adventure sports like zip-lining and rappelling. The group picnic packages are designed to ensure everyone has fun, with engaging activities that promote teamwork and bonding.</p>

<h3>One Day Return Trip from Mumbai</h3>
<p>Glowry Stone Resort makes for the perfect destination for a one-day return trip from Mumbai. Located just a short drive away from the city, the resort provides a quick escape into nature, where you can enjoy outdoor adventures, unwind in the beautiful surroundings, and return home feeling refreshed—all in one day. The resort’s proximity to Mumbai makes it easy to plan a spontaneous trip without the need for an overnight stay, ideal for those with limited time.</p>

<h3>1-Day Picnic Spots in Mumbai and Surrounding Areas</h3>
<p>For those looking to experience the best 1-day picnic spots in Mumbai, Glowry Stone Resort stands out as a top choice. The resort’s beautiful location amidst nature, combined with its wide variety of activities, makes it one of the best options for a quick and enjoyable day out. Whether you are looking for a peaceful retreat or an adventure-filled day, this resort caters to all preferences. You can enjoy a variety of activities, from thrilling adventure sports to peaceful nature walks, all while being close to Mumbai.</p>

<h3>1 Day 1 Night Picnic Spots Near Mumbai</h3>
<p>If you’re looking for a 1 day 1 night picnic spot near Mumbai, Glowry Stone Resort offers a wonderful option. While you can easily enjoy a one-day picnic and return home in the evening, if you prefer to extend your stay, the resort also provides overnight accommodation. You can enjoy a night under the stars, relax by a cozy bonfire, and wake up to the beautiful natural surroundings. This makes it a great option for those who want to stretch their day picnic into an overnight retreat.</p>

<h3>Perfect for Couples and Families</h3>
<p>Whether you're planning a 1 day picnic for couples in Mumbai or a family day out, Glowry Stone Resort has everything you need to make the most of your day. From romantic boat rides for couples to outdoor games and nature walks for families, there’s no shortage of activities to enjoy. The resort is designed to cater to both relaxation and adventure, ensuring that everyone can find something to enjoy during their day at the resort.</p>

<h3>1 Day Picnic Spot for Couples Near Mumbai</h3>
<p>For couples seeking a romantic one-day picnic spot near Mumbai, Glowry Stone Resort offers an intimate setting surrounded by lush greenery, tranquil lakes, and serene landscapes. Enjoy a peaceful boat ride, take a nature walk through picturesque trails, or simply relax by the bonfire in the evening. The resort’s peaceful atmosphere makes it a perfect escape for couples looking to unwind and enjoy each other’s company in a beautiful natural setting.</p>

<h3>Ideal 1 Day Picnic Places in Mumbai</h3>
<p>Located within easy reach of Mumbai, Glowry Stone Resort is one of the best 1-day picnic places in Mumbai. The resort’s wide range of activities ensures that everyone—whether solo travelers, families, or groups—has a fantastic experience. From adventure sports like zip-lining and rappelling to peaceful nature trails, it’s an ideal place to spend a day filled with fun, excitement, and relaxation.</p>

<h3>Best 1-Day Picnic Spot Near Mumbai</h3>
<p>Glowry Stone Resort stands out as one of the top 1-day picnic spots near Mumbai. The resort offers an all-in-one experience, whether you're looking for adventure, nature, or a rejuvenating day out. Enjoy a boating trip, cycling, or challenge yourself with team-building activities in the morning, followed by a relaxing afternoon with a picnic in the beautiful surroundings.</p>

<h3>Perfect 1 Day Picnic Spot for Couples</h3>
<p>If you're looking for a 1-day picnic spot near Mumbai for couples, Glowry Stone Resort is the perfect option. The resort provides a romantic backdrop with its serene lakes, lush greenery, and tranquil ambiance. Couples can enjoy intimate experiences like private boat rides, nature walks, and bonfire nights. Whether it’s a special celebration or just a quiet day out, the resort ensures a memorable time.</p>

<h3>1 Day Picnic Spot Near Mumbai for Families</h3>
<p>For families, Glowry Stone Resort is an excellent 1-day picnic spot near Mumbai. The resort offers a variety of family-friendly activities like outdoor sports, nature walks, boating, and team-building games. With ample space for kids to play and activities for adults to enjoy, it’s the perfect spot for a day full of family fun, relaxation, and bonding.</p>

<h3>1 Day Spot Near Mumbai for Adventure Seekers</h3>
<p>If you're an adventure enthusiast looking for a 1-day spot near Mumbai, Glowry Stone Resort is a fantastic choice. With activities like zip-lining, rappelling, and ATV rides, the resort offers a range of adrenaline-pumping options for those looking to get their heart racing. It’s perfect for a day of adventure sports followed by a relaxing evening amidst nature.</p>

<h3>One-Day Stay Picnic Spot Near Mumbai</h3>
<p>Glowry Stone Resort also offers 1-day stay picnic spots near Mumbai for those who wish to extend their visit and enjoy the resort’s beauty for a longer period. While many people opt for a quick day trip, the resort’s comfortable accommodations and beautiful natural surroundings make it an ideal place for a one-night stay picnic to further unwind and enjoy the peace and tranquility of nature.</p>

<h3>One Night Picnic Spot Near Mumbai</h3>
<p>For those who prefer to stretch their picnic into an overnight adventure, Glowry Stone Resort offers a 1-night picnic spot near Mumbai. Stay in the resort’s cozy rooms or cottages, enjoy a fun-filled day of activities, and unwind by the bonfire under the stars. The next morning, wake up to the serene views of the surrounding nature for a perfect end to your trip.</p>

<h3>Two Days Stay Picnic Spot Near Mumbai</h3>
<p>If you’re looking for a longer escape, Glowry Stone Resort offers a great 2-day stay picnic spot near Mumbai. This gives you the opportunity to enjoy more of what the resort has to offer, whether it’s more time for adventure sports, nature exploration, or simply relaxing by the lake. The extended stay allows you to fully immerse yourself in the beauty and tranquility of the resort, making it perfect for those looking to spend a couple of days away from the city.</p>

<h3>Glowry Stone Resort in Pune Contact Number</h3>
<p>Contact Glowry Stone Resort at [+91 7066665554] for more details. Glowry Stone Resort is not just a picnic spot near Ahmednagar; it’s an experience that brings families closer to nature, provides them with endless opportunities to bond, and ensures a day of relaxation, fun, and enjoyment.</p>


              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  borderWidth: "0px",
                }}
                border="1"
              >
                <colgroup>
                  <col style={{ width: "49.9491%" }} />
                  <col style={{ width: "49.9491%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td style={{ borderWidth: "0px" }}>
                    <p>- One day picnic resorts near Mumbai</p>
<p>- One day picnic in Mumbai</p>
<p>- One day picnic spots near Mumbai for family</p>
<p>- Day picnic near Mumbai</p>
<p>- One day return trip near Mumbai</p>
<p>- 1 day picnic spot in Mumbai</p>
<p>- Day picnic resorts near Mumbai</p>
<p>- 1 day 1 night picnic spots near Mumbai</p>
<p>- 1 day picnic in Mumbai</p>
<p>- 1 day picnic for couples in Mumbai</p>


                    </td>
                    <td style={{ borderWidth: "0px" }}>
                    <p>- 1 day picnic spot in Mumbai for couples</p>
<p>- 1 day picnic places in Mumbai</p>
<p>- 1 day picnic spot near Mumbai</p>
<p>- 1 day picnic spot near Mumbai for couples</p>
<p>- 1 day spot near Mumbai</p>
<p>- 1 day stay picnic spot near Mumbai</p>
<p>- 1 night picnic spot near Mumbai</p>
<p>- 1 night stay picnic spot near Mumbai</p>
<p>- 2 days stay picnic spot near Mumbai</p>
<p>- 1 day picnic near Mumbai</p>

                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>Why Choose Glowry Stone Resort for "One Day Picnic Spot from Mumbai"?</h2>
<p>Glowry Stone Resort offers the perfect getaway for those looking for a one-day picnic spot from Mumbai. Here are the top reasons why it should be your go-to destination for a refreshing and memorable day trip:</p>

<h3>1. Ideal Location - Close to Mumbai</h3>
<p>Glowry Stone Resort is located just a short drive away from Mumbai, making it the perfect choice for a quick one-day escape. The resort’s proximity allows you to enjoy the best of nature without wasting time on long travel. Whether you're looking for a spontaneous getaway or planning a quick weekend retreat, Glowry Stone Resort offers convenience and accessibility, making it ideal for one-day trips.</p>

<h3>2. Variety of Activities for All Interests</h3>
<p>Whether you're a nature lover, adventure enthusiast, or someone looking to relax, Glowry Stone Resort offers a variety of activities to suit all preferences. From boating, nature walks, and cycling, to adventure sports like zip-lining, rappelling, and ATV rides, there is something for everyone. The resort also has team-building games and outdoor challenges for groups or corporate outings, making it a fantastic destination for family picnics or group events.</p>

<h3>3. Perfect for Families, Couples, and Groups</h3>
<p>Glowry Stone Resort caters to a wide range of visitors. Whether you’re looking for a family-friendly day trip, a romantic escape, or a fun-filled group outing, the resort has activities and facilities to suit everyone. Families can enjoy leisurely activities like picnics by the lake or nature trails, while couples can indulge in peaceful boat rides and romantic strolls. For larger groups, the resort offers exciting team-building exercises and outdoor games, making it a versatile option for all types of groups.</p>

<h3>4. Tranquil Natural Setting</h3>
<p>The resort is nestled amidst lush greenery and serene lakes, offering the perfect backdrop for a relaxing day away from the chaos of Mumbai. The peaceful environment ensures a refreshing experience, allowing you to reconnect with nature and escape the noise of the city. Whether you want to unwind by the lakeside or simply enjoy the fresh air, Glowry Stone Resort provides an atmosphere that promotes relaxation and rejuvenation.</p>

<h3>5. Affordable and All-Inclusive Packages</h3>
<p>Glowry Stone Resort offers affordable day-trip packages that include a variety of activities, meals, and more. The resort’s inclusive packages provide great value for money, ensuring you get the most out of your visit without worrying about additional costs. From meals, refreshments, to a range of outdoor activities, the packages are designed to make your one-day picnic experience as convenient and enjoyable as possible.</p>

<h3>6. Perfect for Relaxation and Adventure</h3>
<p>Whether you're seeking peaceful relaxation or an adventure-filled day, Glowry Stone Resort caters to both. Start your day with a nature walk or a boat ride, and in the afternoon, take part in thrilling activities like rock climbing, zip-lining, or rappelling. After a day full of fun and excitement, unwind by the lakeside or enjoy a bonfire in the evening. The resort’s balanced mix of adventure and relaxation ensures you get the best of both worlds.</p>

<h3>7. Excellent Hospitality and Facilities</h3>
<p>At Glowry Stone Resort, you'll experience top-notch hospitality and customer service. The friendly staff is always ready to assist with any requests and ensure your day trip is smooth and enjoyable. The resort also offers excellent facilities, including clean restrooms, comfortable seating areas, and picnic spots, ensuring a convenient and pleasant experience for all visitors.</p>


              <div className="">
                <h2>
                FAQs for Glowry Stone Resort: One Day Picnic Spot From Mumbai?
                </h2>
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className={`faq-question ${
                        activeIndex === index ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(index)}
                    >
                      {index + 1}. {faq.question}
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer">{faq.answer}</div>
                    )}
                  </div>
                ))}
              </div>

                {/* ======================== */}
                <div className="">
                  <h2>
                  Testimonials for Glowry Stone Resort for "One Day Picnic Spot From Mumbai':- {" "}
                  </h2>
                  <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                      <div key={index} className="testimonial-slide">
                        <blockquote>
                          <p>{testimonial.quote}</p>
                          <footer>— {testimonial.author}</footer>
                        </blockquote>
                      </div>
                    ))}
                  </Slider>
                </div>

                <ContactSection></ContactSection>

            </div>
          <div class="col-12 col-md-4">
            <RecentPost></RecentPost>
              <Contactbox></Contactbox>
          </div> 
          </div>
      </div>
    </section>

  <GallerySlider></GallerySlider>

        </>
    )
}  