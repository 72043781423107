import React, { useState } from "react";
import { useRef } from 'react';
import { Link } from 'react-router-dom';  
import { Helmet } from "react-helmet";

// Some other code...


export default function Header(){

    const navbarCollapseRef = useRef(null);

    const handleNavItemClick = () => {
      if (navbarCollapseRef.current.classList.contains('show')) {
        navbarCollapseRef.current.classList.remove('show');
      }
    };
    return(
        <>
        <Helmet>
        <title>Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - About| One Day Picnic Spot Near Ahmednagar | Corporate Outing Resorts Near Pune | Camping Places Near Pune
      </title>
        <meta name="description" content="Glowry Stone Resort, located near Ahmednagar, Maharashtra, offers an ideal destination for a one-day picnic, family outings, and corporate retreats. Book your stay for a perfect getaway from Pune." />
        <meta name="keywords" content="One Day Picnic Spot Near Ahmednagar, One Day Trip Near Ahmednagar, Picnic Spot Near Ahmednagar, Picnic Destination Near Pune, Corporate Outing Resorts Near Ahmednagar, Glowry Stone Resort Near Pune, Family Picnic, Weekend Getaway Near Pune, Resorts Near Pune" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact: +91 7066665554 / +91 9145557771 | Home | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar |  Farm House On Rent in Pune" />
        <meta property="og:description" content="Glowry Stone Resort offers a perfect family-friendly picnic destination near Ahmednagar, ideal for one-day trips and corporate outings. Located just a short drive from Pune." />
        <meta property="og:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <meta property="og:url" content="https://www.glowrystone.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact: +91 7066665554 / +91 9145557771 | Glowry Stone Resort - One Day Picnic Spot Near Ahmednagar" />
        <meta name="twitter:description" content="Glowry Stone Resort, a serene retreat near Ahmednagar, offers family picnics, corporate outings, and more. Book your escape from Pune now!" />
        <meta name="twitter:image" content="https://www.glowrystone.com/ogimage.jpg" /> 
        <link rel="icon" href="favicon.ico" type="image/x-icon" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Resort",
              "name": "Glowry Stone Resort",
              "url": "https://www.glowrystone.com",
              "logo": "https://www.glowrystone.com/logo.png",  // Replace with your logo URL
              "image": "https://www.glowrystone.com/ogimage.jpg", // Replace with your image URL
              "description": "Glowry Stone Resort is an ideal destination for a family-friendly picnic, one-day trips, and corporate outings near Ahmednagar and Pune. It offers serene surroundings, leisure activities, and a memorable experience.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Wadegavhan, Ahmednagar-Nagar Road, Tal-.Parner, Dist- Ahilya Nagar",
                "addressLocality": "Ahmednagar",
                "addressRegion": "Maharashtra",
                "postalCode": "414302",
                "addressCountry": "India"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91 7066665554",
                "telephone": "+91 9145557771",
                "contactType": "Customer Service",
                "areaServed": "IN",
                "availableLanguage": "English"
              },
              "sameAs": [
                "https://www.facebook.com/glowrystone", // Replace with your social media URL if applicable
                "https://twitter.com/glowrystone", // Replace with your social media URL if applicable
                "https://www.instagram.com/glowrystone" // Replace with your social media URL if applicable
              ],
              "offers": {
                "@type": "Offer",
                "url": "https://www.glowrystone.com/book-now",
                "priceCurrency": "INR",
                "price": "400",  // Set the starting price for your resort booking (if applicable)
                "eligibleRegion": {
                  "@type": "Place",
                  "name": "India"
                },
                "priceValidUntil": "2024-12-31",
                "priceSpecification": {
                  "@type": "PriceSpecification",
                  "price": "400",
                  "priceCurrency": "INR",
                  "priceValidUntil": "2024-12-31"
                }
              },
              "telephone": "+91 7066665554 / +91 9145557771",
              "email": "reservation@glowrystone.com",
              "sameAs": [
                "https://www.glowrystone.com"
              ],
              "openingHours": "Mo-Su 08:00-20:00",  // Adjust based on your resort's operating hours
              "additionalType": "http://www.productontology.org/id/Resort"
            }
                starRating: {
      "@type": "Rating",
      bestRating: "4.9",
      ratingValue: "2",
      worstRating: "1.1",
    },
          `}
        </script>
      </Helmet>
           <header class="main_header_area">
 <div className="bg-dark header1">
        <div class="container row text-center pt-2 pb-2">
          <div className="col-12 col-lg-5 ">
            <a href="tel:+91 7058638153" className="text-white fw-bold">
              <i class="bi bi-telephone-fill me-2"></i>
              <a href="tel:+91 706666555" className="text-white">+91 7066665554</a> / 
              <a href="tel:+91 9145557771" className="text-white">+91 9145557771</a>
            </a>
          </div>
          <div className="col-12 col-lg-4 ">
            <a
              href="  mailto:reservation@grandlegacyinnhotels.com"
              className="text-white fw-bold"
            >
              <i class="bi bi-envelope me-2"></i>
              <a href="mailto:reservation@glowrystone.com" className="text-white">
              reservation@glowrystone.com
              </a>
            </a>
          </div>
        </div>
      </div> 
        {/* <!-- Navigation Bar --> */}
        <nav className="navbar navbar-expand-lg bg-white">
      <div className="container mycontainer">
        <a className="navbar-brand" href="/">
          <div className="header__area-menubar-left-logo logo text-center  header-box1">
            {/* <div> 
              <img src="glory stone images/glowry stone gallery images/GS (1).png" alt=""/>
            </div> */}
            <div>
                <img src="logo.jpg" alt="Glowry Stoen Resort logo"/>
            </div>
          </div>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse header-box2"
          id="navbarSupportedContent"
          ref={navbarCollapseRef}
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/" className="nav-link active list" onClick={handleNavItemClick}>
                Home
              </Link>
            </li>

            <li className="nav-item dropdown">
              <Link
                to="/about"
                className="nav-link active list dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About
              </Link>

              <ul className="dropdown-menu">
                <li className="dropdown-item">
                  <Link to="/about" className="nav-link active list" onClick={handleNavItemClick}>
                    About
                  </Link>
                </li>
                <li className="dropdown-item">
                  <Link to="/terms" className="nav-link active list" onClick={handleNavItemClick}>
                    Terms & Conditions
                  </Link>
                </li>
                <li className="dropdown-item">
                  <Link to="/refund" className="nav-link active list" onClick={handleNavItemClick}>
                    Refund Policy
                  </Link>
                </li>
                <li className="dropdown-item">
                  <Link to="/policy" className="nav-link active list" onClick={handleNavItemClick}>
                    Privacy & Policy
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="/gallery" className="nav-link active list" onClick={handleNavItemClick}>
                 Gallery
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/attraction" className="nav-link active list" onClick={handleNavItemClick}>
                 Attraction
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/nearPlaces" className="nav-link active list" onClick={handleNavItemClick}>
                 Nearby Places
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/package" className="nav-link active list" onClick={handleNavItemClick}>
                 Package
              </Link>
            </li>
           

           


            <li className="nav-item">
              <Link to="/activities" className="nav-link active list" onClick={handleNavItemClick}>
                 Activities
              </Link>
            </li>
         
            <li className="nav-item">
              <Link to="/enquiry" className="nav-link active list" onClick={handleNavItemClick}>
                Enquiry
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link active list" onClick={handleNavItemClick}>
                Contact
              </Link>
            </li>

            <li className="nav-item menu-btn text-white ">
              <Link to="/menucard" className="nav-link active list textmenu" onClick={handleNavItemClick}>
                 Menu
              </Link>
            </li>
 
            
          </ul>
        </div>
      </div>
    </nav>
        {/* <!-- Navigation Bar Ends --> */}
    </header>
    {/* <!-- header ends --> */}
   
        </>
    )
}